import { cn } from '@/Lib'
import type { ButtonHTMLAttributes } from 'react'
import { forwardRef, useEffect, useState } from 'react'

interface CopyOrShareProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shareData: ShareData
  copyContent: string
}

export const CopyOrShare = forwardRef<HTMLButtonElement, CopyOrShareProps>(
  ({ className, shareData, copyContent, ...props }, ref) => {
    const [features, setFeatures] = useState({
      clipboard: false,
      share: false
    })

    useEffect(() => {
      if (
        typeof navigator !== 'undefined' &&
        typeof navigator.clipboard !== 'undefined'
      ) {
        setFeatures((prev) => ({ ...prev, clipboard: true }))
      }
      if (
        typeof navigator !== 'undefined' &&
        typeof navigator.share !== 'undefined'
      ) {
        setFeatures((prev) => ({ ...prev, share: true }))
      }
    }, [])

    return (
      <button
        ref={ref}
        onClick={() => {
          if (features.share) {
            navigator.share(shareData).then(
              () => {},
              () => {}
            )
          } else if (features.clipboard) {
            navigator.clipboard.writeText(copyContent).then(
              () => {},
              () => {}
            )
          }
        }}
        className={cn('', className)}
        {...props}
      >
        {props.children && props.children}
        {features.share ? (
          <svg
            className='min-w-max'
            xmlns='http://www.w3.org/2000/svg'
            height='24'
            viewBox='0 -960 960 960'
            width='24'
          >
            <path d='M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z' />
          </svg>
        ) : features.clipboard ? (
          <svg
            className='min-w-max'
            xmlns='http://www.w3.org/2000/svg'
            height='24'
            viewBox='0 -960 960 960'
            width='24'
          >
            <path d='M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z' />
          </svg>
        ) : (
          ''
        )}
      </button>
    )
  }
)

CopyOrShare.displayName = 'CopyOrShare'
