import { cn } from '@/Lib'
import { InertiaLinkProps, Link } from '@inertiajs/react'
import type { ButtonHTMLAttributes, HTMLAttributes, RefAttributes } from 'react'
import { forwardRef } from 'react'

const Card = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex w-full flex-col rounded-[1.25rem] bg-container-strong p-2',
          className
        )}
        {...props}
      />
    )
  }
)
Card.displayName = 'Card'

const CardHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex w-full justify-between px-2 pb-0 pt-2', className)}
      {...props}
    />
  )
)
CardHeader.displayName = 'CardHeader'

const CardFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex w-full justify-between px-2 pb-2 pt-4', className)}
      {...props}
    />
  )
)
CardFooter.displayName = 'CardFooter'

const CardTitle = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  return (
    <h2
      ref={ref}
      className={cn('text-lg font-medium text-strong', className)}
      {...props}
    />
  )
})
CardTitle.displayName = 'CardTitle'

const CardIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex items-center justify-between rounded-full bg-nav p-5 text-medium',
        className
      )}
      {...props}
    />
  )
)
CardIcon.displayName = 'CardIcon'

const CardContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('p-2', className)} {...props} />
  )
)
CardContent.displayName = 'CardContent'

const CardLink = forwardRef<
  never,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ children, className, ...props }, ref) => {
  return (
    <Link
      ref={ref}
      className={cn(
        'my-1 flex rounded-xl p-3 first:mt-0 last-of-type:mb-0 focus-visible:outline-2 focus-visible:outline-focus',
        'hover:bg-nav',
        className
      )}
      // className={({ isActive }) =>
      //   cn(
      //     'my-1 flex rounded-xl p-3 first:mt-0 last-of-type:mb-0 focus-visible:outline-2 focus-visible:outline-focus',
      //     isActive ? 'bg-nav-hover' : 'hover:bg-nav',
      //     className
      //   )
      // }
      {...props}
    >
      {children}
    </Link>
  )
})
CardLink.displayName = 'CardLink'

interface CardButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  noHover?: boolean
}

const CardButton = forwardRef<HTMLButtonElement, CardButtonProps>(
  ({ className, noHover, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          noHover && 'bg-nav',
          'my-1 flex rounded-xl p-3 first:mt-0 last-of-type:mb-0 hover:bg-nav focus-visible:outline-2 focus-visible:outline-focus active:bg-nav-hover',
          className
        )}
        {...props}
      />
    )
  }
)
CardButton.displayName = 'CardButton'

export {
  Card,
  CardButton,
  CardContent,
  CardFooter,
  CardHeader,
  CardIcon,
  CardLink,
  CardTitle
}
