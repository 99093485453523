import * as DialogPrimitive from '@radix-ui/react-dialog'

import { IconButton } from '@/Components/Buttons'
import { cn } from '@/Lib'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  HTMLAttributes,
  forwardRef
} from 'react'

/**
 * Docs for Dialog primitive
 * https://www.radix-ui.com/primitives/docs/components/dialog
 */

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = forwardRef<
  ElementRef<typeof DialogPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-scrim/75 backdrop-blur-sm data-[state=closed]:animate-overlayHide data-[state=open]:animate-overlayShow',
      className
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

type DialogContentProps = {
  form?: boolean // Forces the dialog to the top on mobile to account for the virtual keyboard
} & ComponentPropsWithoutRef<typeof DialogPrimitive.Content>

const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ form, className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay
      className={cn(
        'flex h-full w-screen justify-center overflow-y-scroll p-4',
        form ? 'items-start md:items-center ' : 'items-center'
      )}
    >
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          'relative mx-auto flex w-full max-w-md flex-col gap-5 rounded-xl bg-container-strong p-4 shadow-lg duration-200 data-[state=closed]:animate-contentHide data-[state=open]:animate-contentShow',
          className
        )}
        {...props}
      >
        {children}
        <DialogPrimitive.Close asChild aria-label='Close'>
          <IconButton className='absolute right-4 top-4'>close</IconButton>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogOverlay>
  </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col pr-9', className)} {...props} />
)
DialogHeader.displayName = 'DialogHeader'

const DialogFooter = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex justify-end gap-6', className)} {...props} />
)
DialogFooter.displayName = 'DialogFooter'

const DialogTitle = forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn('text-lg font-medium text-strong', className)}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('text-medium', className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger
}
