import { Icon } from '@/Components/Icon'
import { cn } from '@/Lib'
import type { ButtonHTMLAttributes } from 'react'
import { forwardRef } from 'react'

/**
 * TODO: Button refactor:
 * - Button - this is the default solid blue button.
 * - TextButton - a button styled like a TextRouter.
 * - OutlineButton - the outline style button.
 * - IconButton - a button that houses an Icon - should just take text of the icon as children.
 */

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shrink?: boolean // sm:max-w-fit
}

export const Button = forwardRef<never, ButtonProps>(
  ({ children, shrink, ...buttonProps }, ref) => {
    return (
      <button
        ref={ref}
        {...buttonProps}
        disabled={buttonProps.disabled}
        className={cn(
          'flex h-12 w-full items-center justify-center rounded-full border border-transparent px-6 font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed disabled:bg-disabled disabled:text-disabled',
          'bg-primary text-white focus-visible:outline-focus active:ring-blue-400 hover:enabled:bg-blue-400',
          shrink && 'sm:max-w-fit',
          buttonProps.className
        )}
      >
        {children}
      </button>
    )
  }
)

Button.displayName = 'Button'

export const OutlineButton = forwardRef<never, ButtonProps>(
  ({ children, shrink, ...buttonProps }, ref) => {
    return (
      <button
        ref={ref}
        {...buttonProps}
        disabled={buttonProps.disabled}
        className={cn(
          'flex h-12 w-full items-center justify-center rounded-full border border-transparent px-6 font-medium text-primary outline outline-2 -outline-offset-2 outline-blue-500 hover:bg-container-primary focus-visible:bg-container-primary disabled:cursor-not-allowed disabled:bg-disabled disabled:text-disabled',
          shrink && 'sm:max-w-fit',
          buttonProps.className
        )}
      >
        {children}
      </button>
    )
  }
)

OutlineButton.displayName = 'OutlineButton'

interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const TextButton = forwardRef<never, TextButtonProps>(
  ({ children, ...buttonProps }, ref) => {
    return (
      <button
        ref={ref}
        {...buttonProps}
        disabled={buttonProps.disabled}
        className={cn(
          'rounded text-sm font-medium text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-blue-500 active:ring-blue-400 disabled:cursor-not-allowed disabled:text-disabled',
          buttonProps.className
        )}
      >
        {children}
      </button>
    )
  }
)

TextButton.displayName = 'TextButton'

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  shrink?: boolean // sm:max-w-fit
}

export const IconButton = forwardRef<never, IconButtonProps>(
  ({ children, className, ...buttonProps }, ref) => {
    return (
      <button
        ref={ref}
        {...buttonProps}
        disabled={buttonProps.disabled}
        className={cn(
          '-m-3 flex h-max w-max rounded-lg p-3 text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-4 focus-visible:outline-blue-500 active:ring-blue-400 disabled:cursor-not-allowed disabled:text-disabled',
          className
        )}
      >
        <Icon>{children}</Icon>
      </button>
    )
  }
)

IconButton.displayName = 'IconButton'
